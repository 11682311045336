<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 년도 -->
          <c-datepicker
            :range="true"
            type="year"
            defaultStart="-1y"
            defaultEnd="0y"
            label="LBLYEAR"
            name="years"
            v-model="searchParam.years"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            stepperGrpCd="HEA_MUSCLE_SURVEY_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="heaMuscleSurveyStepCd"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.heaMuscleSurveyStepCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-investigation-plan
            type="search"
            label="근골격계 조사계획"
            :plantCd="searchParam.plantCd"
            name="heaInvestigationPlanId"
            v-model="searchParam.heaInvestigationPlanId"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      title="근골격계질환 증상조사 설문요청 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'symptomSurvey',
  data() {
    return {
      searchParam: {
        plantCd: null,
        years: [],
        heaMuscleSurveyStepCd: null,
        userId: '',
        heaInvestigationPlanId: '',
        heaSurveyCategoryCd: '',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: false,
            style: 'width:100px',
          },
          {
            name: 'investigationPlanName',
            field: 'investigationPlanName',
            label: '조사계획명',
            align: 'left',
            sortable: false,
            style: 'width:250px',
          },
          {
            name: 'year',
            field: 'year',
            label: 'LBLYEAR',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'worker',
            field: 'worker',
            label: '작업자',
            child: [
              {
                name: 'userName',
                field: 'userName',
                label: 'LBLNAME',
                align: 'center',
                sortable: false,
                style: 'width:80px',
                type: 'link'
              },
              {
                name: 'deptName',
                field: 'deptName',
                label: 'LBLDEPT',
                align: 'center',
                sortable: false,
                style: 'width:80px',
              },
              {
                name: 'heaMuscleSurveyStepName',
                field: 'heaMuscleSurveyStepName',
                label: '설문지 작성현황',
                align: 'center',
                sortable: false,
                style: 'width:80px',
              },
            ]
          },
          {
            name: 'processSopNames',
            field: 'processSopNames',
            label: '관련 공정/단위작업',
            align: 'left',
            sortable: false,
            style: 'width:250px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: false,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.muscleSystem.muscleSurvey.list.url
      // code setting
      // this.$set(this.searchParam, 'userId', this.$store.getters.user.userId);
      this.$set(this.searchParam, 'heaSurveyCategoryCd', this.$route.meta.params.heaSurveyCategoryCd)
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col) {
      if (this.$route.meta.params.heaSurveyCategoryCd === 'MSC0000001') {
        this.popupOptions.title = '근골격계질환 정기 증상조사 설문지';
        this.popupOptions.target = () => import(`${'./symptomSurveyRoutineDetail.vue'}`);
      } else if (this.$route.meta.params.heaSurveyCategoryCd === 'MSC0000005') {
        this.popupOptions.title = '근골격계질환 수시 증상조사 설문지';
        this.popupOptions.target = () => import(`${'./symptomSurveyDetail.vue'}`);
      } else if (this.$route.meta.params.heaSurveyCategoryCd === 'MSC0000010') {
        this.popupOptions.title = '근골격계질환 증상조사 설문지';
        this.popupOptions.target = () => import(`${'./symptomSurveyRoutineDetail.vue'}`);
      }
      this.popupOptions.param = {
        heaMuscleWorkerSurveyId: row.heaMuscleWorkerSurveyId,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
